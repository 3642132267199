import http from "./http-common"

const upload = (file, uploadId, onUploadProgress, module) => {
  let formData = new FormData()

  formData.append("file", file)
  formData.append("uploadId", uploadId)
  formData.append("module", module)

  return http.post("upload/", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  })
}

const getFiles = uploadId => {
  if (uploadId) {
    return http.get("files/?id=" + uploadId)
  } else {
    return http.get("files/")
  }
}

const deleteFile = (fileId, uploadId) => {
  return http.get("delete/?file_id=" + fileId + "&id=" + uploadId)
}

// eslint-disable-next-line
export default {
  upload,
  getFiles,
  deleteFile,
}
