import axios from "axios"

let url
if (process.env.NODE_ENV === "development") {
  url = "http://localhost/malgen/scomos/upload/files/"
} else {
  url = "https://admin.malgen.cz/upload/files/"
}

export default axios.create({
  baseURL: url,
  headers: {
    "Content-type": "application/json",
  },
})
