import React, { useState, useRef, useEffect } from "react"
import { Helmet } from "react-helmet"
import { Link, navigate } from "gatsby"
import axiosInstance from "../client"
import { Error } from "../components/form/Error"
import { ChevronLeftIcon, QuestionMarkCircleIcon } from "@heroicons/react/solid"
import { useForm } from "react-hook-form"
import ReCAPTCHA from "react-google-recaptcha"
import { FaAsterisk } from "react-icons/fa"
import FileUpload from "../components/fileUpload"

const PoptavkaFormPage = ({ location }) => {
  const [loading, setLoading] = useState(false)
  const [sklonInfo, setSklonInfo] = useState(false)
  const [spotrebaInfo, setSpotrebaInfo] = useState(false)
  const [uploadId, setId] = useState()

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm()

  const recaptchaRef = useRef()
  const onSubmitWithReCAPTCHA = async data => {
    const token = await recaptchaRef.current.executeAsync()
    onSubmit(data, token)
  }

  useEffect(() => {
    if (!uploadId) {
      const id =
        "upload_" +
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15)
      setId(id)
      setValue("uploadId", id)
    }
    // eslint-disable-next-line
  }, [uploadId])

  const onSubmit = async data => {
    setLoading(true)
    //console.log(data);
    //return;

    await axiosInstance
      .post("forms/send/1/", data)
      .then(res => {
        navigate("/formular-odeslan/")
        setLoading(false)
      })
      .catch(err => {
        console.log(err?.data?.message)
        setLoading(false)
      })
  }
  const typ = watch("typ")

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Fotovoltaika - poptávkový formulář | Malgen</title>
      </Helmet>
      <div className="py-10">
        <div className="  flex px-10  max-w-7xl mx-auto">
          <div className="space-y-4 sm:space-y-0   ">
            <Link
              to={"/fotovoltaika/"}
              className="flex items-center justify-center space-x-4 px-2 py-2 border border-transparent text-base font-medium rounded-md   text-gray-700 hover:bg-gray-100 sm:px-8"
            >
              <ChevronLeftIcon className="w-5 h-5" /> <span>Zpět</span>
            </Link>
          </div>
        </div>

        <div className="pt-12 sm:pt-16">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-4xl mx-auto text-center  ">
              <h2 className="text-2xl inset-0 sm:text-4xl">
                <strong>Fotovoltaika</strong> – kalkulace návratnosti
              </h2>
            </div>
          </div>
          <div className="mt-10 pb-12   sm:pb-16">
            <div className="relative">
              <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="max-w-7xl mx-auto ">
                  <div>
                    <div className="grid grid-cols-2 gap-5 max-w-3xl mx-auto bg-gray-100 rounded-md py-5">
                      <div className="col-span-2">
                        <form onSubmit={handleSubmit(onSubmitWithReCAPTCHA)}>
                          <input
                            type="hidden"
                            name="form_name"
                            defaultValue="contact"
                            {...register("form_name", { required: true })}
                          />
                          <input
                            type="hidden"
                            {...register("uploadId")}
                            name="uploadId"
                            defaultValue={uploadId}
                          />
                          <div className="mb-5 px-10">
                            <label className="text-xl   text-gray-900 font-bold">
                              Mám zájem o fotovoltaiku pro{" "}
                              <FaAsterisk className="inline ml-2 text-red-600 w-3" />
                            </label>
                            <fieldset className="mt-4">
                              <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                <div className="flex items-center">
                                  <input
                                    id="osoba"
                                    name="typ"
                                    type="radio"
                                    {...register("typ", {
                                      required: true,
                                    })}
                                    defaultValue="domácnost"
                                    className="h-4 w-4 border-gray-300 text-sky-600 focus:ring-sky-500"
                                  />
                                  <label
                                    htmlFor="osoba"
                                    className="ml-3 block text-sm font-medium text-gray-700"
                                  >
                                    domácnost
                                  </label>
                                </div>
                                <div className="flex items-center">
                                  <input
                                    id="firma"
                                    name="typ"
                                    type="radio"
                                    {...register("typ", {
                                      required: true,
                                    })}
                                    defaultValue="firma"
                                    className="h-4 w-4 border-gray-300 text-sky-600 focus:ring-sky-500"
                                  />
                                  <label
                                    htmlFor="firma"
                                    className="ml-3 block text-sm font-medium text-gray-700"
                                  >
                                    firmu
                                  </label>
                                </div>
                                <div className="flex items-center">
                                  <input
                                    id="svj"
                                    name="typ"
                                    type="radio"
                                    {...register("typ", {
                                      required: true,
                                    })}
                                    defaultValue="SVJ"
                                    className="h-4 w-4 border-gray-300 text-sky-600 focus:ring-sky-500"
                                  />
                                  <label
                                    htmlFor="svj"
                                    className="ml-3 block text-sm font-medium text-gray-700"
                                  >
                                    společenství vlastníků/bytové družstvo
                                  </label>
                                </div>
                              </div>
                              {errors.typ && (
                                <Error text="Vyberte prosím pro koho je kalkulace určena" />
                              )}
                              {(typ === "firma" || typ === "SVJ") && (
                                <div className=" grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6  py-5">
                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="ic"
                                      className="block text-sm font-bold text-gray-700"
                                    >
                                      IČ{" "}
                                      <FaAsterisk className="inline ml-2 text-red-600 w-3" />
                                    </label>
                                    {typ === "firma" ? (
                                      <div className="mt-1">
                                        <input
                                          type="text"
                                          name="ic"
                                          id="ic"
                                          {...register("ic", {
                                            required: true,
                                          })}
                                          className="shadow-sm  block w-full sm:text-sm border-gray-300 rounded-md"
                                        />
                                        {errors.ic && (
                                          <Error text="Vyplňte IČ" />
                                        )}
                                      </div>
                                    ) : (
                                      <div className="mt-1">
                                        <input
                                          type="text"
                                          name="ic_svj"
                                          id="ic"
                                          {...register("ic_svj", {
                                            required: true,
                                          })}
                                          className="shadow-sm  block w-full sm:text-sm border-gray-300 rounded-md"
                                        />
                                        {errors.ic_svj && (
                                          <Error text="Vyplňte IČ" />
                                        )}
                                      </div>
                                    )}
                                  </div>

                                  {typ === "SVJ" && (
                                    <div className="sm:col-span-3">
                                      <label
                                        htmlFor="jednotek"
                                        className="block text-sm font-bold text-gray-700"
                                      >
                                        Počet bytových jednotek{" "}
                                        <FaAsterisk className="inline ml-2 text-red-600 w-3" />
                                      </label>
                                      <div className="mt-1">
                                        <input
                                          type="text"
                                          name="jednotek"
                                          id="jednotek"
                                          {...register("jednotek", {
                                            required: true,
                                          })}
                                          className="shadow-sm  block w-full sm:text-sm border-gray-300 rounded-md"
                                        />
                                        {errors.jednotek && (
                                          <Error text="Vyplňte počet jednotek" />
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </fieldset>
                          </div>
                          <div className=" grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6 bg-gray-100 py-5 px-10 rounded-md ">
                            <div className="sm:col-span-3">
                              <label
                                htmlFor="fname"
                                className="block text-sm font-bold text-gray-700"
                              >
                                Jméno{" "}
                                <FaAsterisk className="inline ml-2 text-red-600 w-3" />
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="fname"
                                  id="fname"
                                  {...register("fname", { required: true })}
                                  className="shadow-sm  block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                                {errors.fname && <Error text="Vyplňte jméno" />}
                              </div>
                            </div>
                            <div className="sm:col-span-3">
                              <label
                                htmlFor="surname"
                                className="block text-sm font-bold text-gray-700"
                              >
                                Příjmení{" "}
                                <FaAsterisk className="inline ml-2 text-red-600 w-3" />
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="surname"
                                  id="surname"
                                  {...register("surname", { required: true })}
                                  className="shadow-sm  block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                                {errors.surname && (
                                  <Error text="Vyplňte příjmení" />
                                )}
                              </div>
                            </div>

                            <div className="sm:col-span-4">
                              <label
                                htmlFor="email"
                                className="block text-sm  font-bold text-gray-700 "
                              >
                                E-mail{" "}
                                <FaAsterisk className="inline ml-2 text-red-600 w-3" />
                              </label>
                              <div className="mt-1">
                                <input
                                  id="email"
                                  name="email"
                                  type="email"
                                  {...register("email", { required: true })}
                                  className="shadow-sm  block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                                {errors.email && (
                                  <Error text="Vyplňte e-mail" />
                                )}
                              </div>
                            </div>
                            <div className="sm:col-span-2">
                              <label
                                htmlFor="tel"
                                className="block text-sm font-bold text-gray-700"
                              >
                                Telefon{" "}
                                <FaAsterisk className="inline ml-2 text-red-600 w-3" />
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="tel"
                                  id="tel"
                                  {...register("tel", { required: true })}
                                  className="shadow-sm  block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                                {errors.tel && <Error text="Vyplňte telefon" />}
                              </div>
                            </div>
                            <div className="sm:col-span-6 font-bold text-xl mt-5">
                              Adresa instalace
                            </div>
                            <div className="sm:col-span-6">
                              <label
                                htmlFor="street"
                                className="block text-sm font-bold text-gray-700"
                              >
                                Ulice, číslo domu{" "}
                                <FaAsterisk className="inline ml-2 text-red-600 w-3" />
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="street"
                                  id="street"
                                  {...register("street", { required: true })}
                                  className="shadow-sm  block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                                {errors.street && (
                                  <Error text="Vyplňte ulici" />
                                )}
                              </div>
                            </div>

                            <div className="sm:col-span-4">
                              <label
                                htmlFor="city"
                                className="block text-sm font-bold text-gray-700 "
                              >
                                Město{" "}
                                <FaAsterisk className="inline ml-2 text-red-600 w-3" />
                              </label>
                              <div className="mt-1">
                                <input
                                  id="city"
                                  type="text"
                                  {...register("city", { required: true })}
                                  className="shadow-sm  block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                                {errors.city && <Error text="Vyplňte město" />}
                              </div>
                            </div>
                            <div className="sm:col-span-2">
                              <label
                                htmlFor="zip"
                                className="block text-sm font-bold text-gray-700"
                              >
                                PSČ{" "}
                                <FaAsterisk className="inline ml-2 text-red-600 w-3" />
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  id="zip"
                                  {...register("zip", { required: true })}
                                  className="shadow-sm  block w-full sm:text-sm border-gray-300 rounded-md"
                                />
                                {errors.zip && <Error text="Vyplňte PSČ" />}
                              </div>
                            </div>
                          </div>
                          <div className=" grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6 bg-gray-100 py-5 px-10 rounded-md ">
                            <div className="sm:col-span-3">
                              <label
                                htmlFor="spotreba"
                                className="  text-sm w-full flex justify-between font-bold text-gray-700"
                              >
                                <div>
                                  Průměrná roční spotřeba
                                  <FaAsterisk className="inline ml-2 text-red-600 w-3" />
                                </div>
                                <button
                                  type="button"
                                  onClick={() => setSpotrebaInfo(!spotrebaInfo)}
                                >
                                  <QuestionMarkCircleIcon className="text-blue-600 w-5 h-5" />
                                </button>
                              </label>
                              <div className="mt-1  flex rounded-md shadow-sm">
                                <div className="relative flex flex-grow items-stretch focus-within:z-10">
                                  <input
                                    type="number"
                                    name="spotreba"
                                    id="spotreba"
                                    {...register("spotreba", {
                                      required: true,
                                    })}
                                    className="block w-full rounded-none rounded-l-md border-gray-300   focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
                                  />
                                </div>
                                <div className="relative w-16 -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500">
                                  <span>kWh</span>
                                </div>
                              </div>
                              {spotrebaInfo && (
                                <>
                                  <div className="text-sm p-5 mb-5 mt-2 bg-white rounded-md border">
                                    <p>
                                      Průměrnou roční spotřebu naleznete ve svém
                                      ročním vyúčtování od dodavatele, pokud jej
                                      nemáte u sebe můžete použít následující
                                      orientační údaje:
                                      <br />
                                      Běžná spotřeba + svícení (1-2 osoby): 1
                                      800 kWh
                                      <br />
                                      Běžná spotřeba + svícení (3-5 osob): 3 200
                                      kWh
                                      <br />
                                      Elektrický ohřev vody (1-2 osoby): 6 500
                                      kWh
                                      <br />
                                      Elektrický ohřev vody (3-5 osob): 10 000
                                      kWh <br />
                                      Elektrický ohřev vody a vytápění –
                                      přímotopy (1-2 osoby): 13 800 kWh <br />
                                      Elektrický ohřev vody a vytápění –
                                      přímotopy (3-5 osob): 19 000 kWh
                                    </p>
                                  </div>
                                </>
                              )}
                              {errors.spotreba && (
                                <Error text="Vyplňte roční spotřebu" />
                              )}
                            </div>
                            <div className="sm:col-span-3">
                              <label
                                htmlFor="strecha"
                                className="flex justify-between text-sm font-bold text-gray-700"
                              >
                                <div>
                                  Sklon střechy{" "}
                                  <FaAsterisk className="inline ml-2 text-red-600 w-3" />
                                </div>
                                <button
                                  type="button"
                                  onClick={() => setSklonInfo(!sklonInfo)}
                                >
                                  <QuestionMarkCircleIcon className="text-blue-600 w-5 h-5" />
                                </button>
                              </label>
                              <div className="mt-1">
                                <select
                                  id="strecha"
                                  name="strecha"
                                  defaultValue=""
                                  className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm"
                                  {...register("strecha", { required: true })}
                                >
                                  <option value="" disabled>
                                    vyberte typ střechy
                                  </option>
                                  <option value="plochá">plochá</option>
                                  <option value="sklon do 25°">
                                    sklon do 25°
                                  </option>
                                  <option value="sklon 25° - 35°">
                                    sklon 25° - 35°
                                  </option>
                                  <option value="sklon více než 35°">
                                    sklon více než 35°
                                  </option>
                                </select>

                                {errors.strecha && (
                                  <Error text="Vyplňte typ střechy" />
                                )}

                                {sklonInfo && (
                                  <>
                                    <div className="text-sm p-5 mb-5 mt-2 bg-white rounded-md border">
                                      Panely je možné instalovat na všechny typy
                                      střech, jak ploché, tak sedlové. Ideální
                                      sklon střechy se pohybuje mezi 25–38
                                      stupni.
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="sm:col-span-3">
                              <label
                                htmlFor="krytina"
                                className="block text-sm font-bold text-gray-700"
                              >
                                Typ střešní krytiny{" "}
                                <FaAsterisk className="inline ml-2 text-red-600 w-3" />
                              </label>
                              <div className="mt-1">
                                <select
                                  id="krytina"
                                  name="krytina"
                                  defaultValue=""
                                  className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm"
                                  {...register("krytina", { required: true })}
                                >
                                  <option value="" disabled>
                                    vyberte typ střešní krytiny
                                  </option>
                                  <option value="plechová">plechová</option>
                                  <option value="lepenka">lepenka</option>
                                  <option value="tašky">tašky</option>
                                  <option value="eternit">eternit</option>
                                </select>

                                {errors.krytina && (
                                  <Error text="Vyplňte typ střešní krytiny" />
                                )}
                              </div>
                            </div>
                            <div className="sm:col-span-3">
                              <label
                                htmlFor="plocha"
                                className="block text-sm font-bold text-gray-700"
                              >
                                <div>
                                  Plocha střechy
                                  <FaAsterisk className="inline ml-2 text-red-600 w-3" />
                                </div>
                              </label>
                              <div className="mt-1  flex rounded-md shadow-sm">
                                <div className="relative flex flex-grow items-stretch focus-within:z-10">
                                  <input
                                    type="text"
                                    name="plocha"
                                    id="plocha"
                                    {...register("plocha", { required: true })}
                                    className="block w-full rounded-none rounded-l-md border-gray-300   focus:border-sky-500 focus:ring-sky-500 sm:text-sm"
                                  />
                                </div>
                                <div className="relative w-16 -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500">
                                  <span>
                                    m<sup>2</sup>
                                  </span>
                                </div>
                              </div>

                              {errors.plocha && (
                                <Error text="Vyplňte plochu střechy" />
                              )}
                            </div>
                            <div className="sm:col-span-3 mt-3 ">
                              <div>
                                <label className=" text-sm font-bold  text-gray-700 ">
                                  Vlastníte elektromobil?
                                  <FaAsterisk className="inline ml-2 text-red-600 w-3" />
                                </label>
                                <fieldset className="mt-1">
                                  <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                    <div className="flex items-center">
                                      <input
                                        id="el_ano"
                                        name="elektromobil"
                                        type="radio"
                                        {...register("elektromobil", {
                                          required: true,
                                        })}
                                        defaultValue="ano"
                                        className="h-4 w-4 border-gray-300 text-sky-600 focus:ring-sky-500"
                                      />
                                      <label
                                        htmlFor="el_ano"
                                        className="ml-3 block text-sm font-medium text-gray-700"
                                      >
                                        ano
                                      </label>
                                    </div>
                                    <div className="flex items-center">
                                      <input
                                        id="el_ne"
                                        name="eletromobil"
                                        type="radio"
                                        {...register("elektromobil", {
                                          required: true,
                                        })}
                                        defaultValue="ne"
                                        className="h-4 w-4 border-gray-300 text-sky-600 focus:ring-sky-500"
                                      />
                                      <label
                                        htmlFor="el_ne"
                                        className="ml-3 block text-sm font-medium text-gray-700"
                                      >
                                        ne
                                      </label>
                                    </div>
                                  </div>
                                </fieldset>
                              </div>
                              {errors.elektromobil && (
                                <Error text="Vyberte prosím jednu z možností" />
                              )}
                            </div>
                            <div className="sm:col-span-3 mt-3">
                              <div>
                                <label className=" text-sm font-bold  text-gray-700 ">
                                  Potřebujete financování?
                                  <FaAsterisk className="inline ml-2 text-red-600 w-3" />
                                </label>
                                <fieldset className="mt-1">
                                  <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                    <div className="flex items-center">
                                      <input
                                        id="fin_ano"
                                        name="financovani"
                                        type="radio"
                                        {...register("financovani", {
                                          required: true,
                                        })}
                                        defaultValue="ano"
                                        className="h-4 w-4 border-gray-300 text-sky-600 focus:ring-sky-500"
                                      />
                                      <label
                                        htmlFor="fin_ano"
                                        className="ml-3 block text-sm font-medium text-gray-700"
                                      >
                                        ano
                                      </label>
                                    </div>
                                    <div className="flex items-center">
                                      <input
                                        id="fin_ne"
                                        name="financovani"
                                        type="radio"
                                        {...register("financovani", {
                                          required: true,
                                        })}
                                        defaultValue="ne"
                                        className="h-4 w-4 border-gray-300 text-sky-600 focus:ring-sky-500"
                                      />
                                      <label
                                        htmlFor="fin_ne"
                                        className="ml-3 block text-sm font-medium text-gray-700"
                                      >
                                        ne
                                      </label>
                                    </div>
                                  </div>
                                </fieldset>
                              </div>
                              {errors.financovani && (
                                <Error text="Vyberte prosím jednu z možností" />
                              )}
                            </div>
                          </div>
                          <div>
                            <div className=" grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6  py-5 px-10   ">
                              <div className="sm:col-span-6">
                                <label
                                  htmlFor="street_address"
                                  className="block text-sm mt-3 font-medium text-gray-700"
                                >
                                  Můžete nahrát fotografie místa realizace
                                  (nepovinné)
                                </label>
                                <FileUpload
                                  uploadId={uploadId}
                                  module={"form"}
                                />
                              </div>
                              <div className="sm:col-span-6">
                                <label
                                  htmlFor="street_address"
                                  className="block text-sm   text-gray-700"
                                >
                                  Poznámka
                                </label>
                                <div className="mt-1">
                                  <textarea
                                    className="rounded border border-gray-300 w-full text-sm h-32"
                                    {...register("note")}
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                          <ReCAPTCHA
                            ref={recaptchaRef}
                            size="invisible"
                            sitekey="6LfRhwYjAAAAAOusF3kqlQpQmFahugE3uAs3lhNK"
                          />
                          <div className="mx-10">
                            {loading ? (
                              <button
                                disabled
                                className="bg-gray-200 text-gray-800 font-bold py-4 px-20  rounded cursor-default"
                              >
                                <svg
                                  className="animate-spin -ml-1 mr-3 h-5 w-5 inline"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                  ></circle>
                                  <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                  ></path>
                                </svg>
                                <span>Odesílání...</span>
                              </button>
                            ) : (
                              <>
                                <button
                                  type="submit"
                                  className="bg-gray-700 text-white font-bold py-4 px-20  rounded hover:bg-gray-600"
                                >
                                  Odeslat
                                </button>
                              </>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PoptavkaFormPage
